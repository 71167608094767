<template>
  <div>new</div>
</template>

<script>
import router from '../router';
import { mapGetters } from "vuex";

export default {
  name: 'NewOrderRedirect',

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      requestedPath: "orders/requestedPath",
    }),
  },
  methods: {
    setActiveOrderState(isActive) {
      this.$store.commit("orders/setActiveOrderState", isActive);
    },
  },
  async mounted() {
    if (this.requestedPath) {
      await router.push({ path: this.requestedPath });
      await this.$store.commit("orders/setRequestedPath", null);
      this.setActiveOrderState(false)
    } else {
      await router.push({ name: "Order", params: { tableID: null, id: "new" } });
    }
  }
};
</script>
