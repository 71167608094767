<template>
  <b-modal
    id="hold-order-modal"
    :ok-title="$t('orderscreen.yes').toUpperCase()"
    :cancel-title="$t('orderscreen.no').toUpperCase()"
    title=""
    hide-header
    modal-class="success-popup"
    footer-class="d-flex justify-content-center align-items-center"
    cancel-variant="outline-warning"
    ok-variant="warning"
    @ok="holdOrder"
    @cancel="cancelModal"
    no-fade
  >
    <div class="text-center modal-complete">
      <svg class="icon-icon-popup-hold">
        <use xlink:href="#icon-icon-popup-hold" />
      </svg>

      <h2>
        {{ $t('orderscreen.hold-this-order') }}
      </h2>
      <div class="d-flex justify-content-center align-items-center">
        <textarea
          v-model="description"
          :placeholder="$t('orderscreen.note-this-placeholder')"
          class="popup-control mb-4"
        />'
      </div>
    </div>
  </b-modal>
</template>

<script>
  export default {
    name: "HoldOrder",
    data () {
      return {
        description: ''
      }
    },
    methods: {
      holdOrder() {
        const {description} = this;
        this.$emit('hold-order', {description})
      },
      cancelModal() {
        this.$bvModal.hide('hold-order-modal')
        this.description = '';
      }
    }
  }
</script>

<style scoped>

</style>
