import { mapGetters } from "vuex"
import Pusher from 'pusher-js'

const soundNewOrder = new Audio(require("../assets/sounds/online_order_notificaiton.wav"));

export default {
  data: () => ({
    pusher: null,
    userHasInteracted: false,
  }),
  computed: {
    ...mapGetters({
        baseUrl: "config/baseUrl",
        store: "config/store",
    }),
  },
  created() {
    // Add event listeners to track user interaction
    window.addEventListener('click', this.userInteraction);
    window.addEventListener('keydown', this.userInteraction);
  },
  beforeDestroy() {
    // Clean up event listeners when component is destroyed
    window.removeEventListener('click', this.userInteraction);
    window.removeEventListener('keydown', this.userInteraction);
  },
  methods: {
    userInteraction() {
      this.userHasInteracted = true;
    },
    subscribe() {
        let clientName = this.baseUrl.split("//")[1].split(".")[0].replace(":", "_");
        let storeId;
        if (this.store && this.store.id) {
          storeId = this.store.id;
        } else {
          storeId = "demo";
        }
        let subscribeChannel = `${clientName}_${storeId}_channel`;
        console.log('subscribeChannel', subscribeChannel)
        this.pusher = new Pusher('33c344d94b71b318f01d', { cluster: 'mt1', debug: true, });
        let channel = this.pusher.subscribe(subscribeChannel);
        channel.bind("pusher:subscription_succeeded", () => {
            console.log("Subscription succeeded!");
        });
        channel.bind("orders-event", (data) => {
          if (this.$route.name === "Tables") {
            this.$store.dispatch("orders/fetchOpenOrders");
            this.$store.dispatch("orders/fetchUpdatedTableOrders", data.instance_id)
            this.$store.dispatch("config/fetchSections");
          }
          if(data.call && data.call === "fetchPendingOrder") {
            if (this.userHasInteracted) {
              soundNewOrder.play().catch(error => {
                console.error("Error playing sound:", error);
              });
            }
            setTimeout(() => {
              this.$store.dispatch("orders/fetchOnlineOrders");
            }, 5000);
            if (this.$route.name !== "OnlineOrder" && this.$route.name !== 'Pin' && this.$route.name !== 'Auth') {
              this.$bvModal.show("new-order-received")
            } else {
              this.$bvModal.hide("new-order-received")
            }
          }
          this.$forceUpdate();
        });
        channel.bind("new-version", () => {
          this.$bvModal.show('sw-update-modal')
        });
      }
    
  },
  
}